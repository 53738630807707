import React from 'react';
import styled from 'styled-components';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import qs from 'query-string';
import Theme from '../../Theme';

function Info(props) {
  const [article, setArticle] = React.useState(null);
  const {id} = qs.parse(props.location.search);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        setArticle(
          await JStorage.fetchOneDocument('Article_Default', {id}, null, {
            token: null,
          }),
        );
      } catch (ex) {
        console.warn('REV_DBG', ex);
      }
      AppActions.setLoading(false);
    }

    fetchData();
  }, [id]);

  return (
    <Wrapper>
      {article && (
        <>
          <h1>{article.title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: article.raw_html?.enabled
                ? article.raw_html.html
                : article.html,
            }}
          />
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  h1 {
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 4px solid ${Theme.colors.main};
    margin-bottom: 25px;
  }
`;

export default withPageEntry(Info);
